/**
 * 营销活动管理-事件日志管理
 * luxinwen
 * 2023-04-12
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="top-form" v-grant="'OSP_MARKETING_LOG_QUERY'">
        <Form :model="formData" inline>
          <FormItem>
            <Input class="width-s" v-model="formData.eventId" placeholder="请输入事件ID" clearable />
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.eventName" placeholder="请输入事件名称" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.eventType" placeholder="请选择事件类型" clearable>
              <Option v-for="(item, index) in eventTypeList" :key="'eventType' + index" :value="item.code">{{ item.desc }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Input class="width-s" v-model="formData.memberId" placeholder="请输入会员编码" clearable />
          </FormItem>
          <FormItem>
            <Select class="width-s" v-model="formData.executeResult" placeholder="请选择执行结果" clearable>
              <Option v-for="(value, key) in executeResultList" :key="'executeResult' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <FormItem>
            <Button type="primary" @click="queryData">查询</Button>
          </FormItem>
        </Form>
      </div>
      <div class="page-main-content">
        <sp-table :columns="filterColumns" :data="tableData" :loading="tableLoading" :total="pager.total" :page="pager.pageNum" :page-size="pager.pageSize" @change="changePage">
          <template slot-scope="params" slot="eventType">
            <span>{{ (eventTypeList.find(item => item.code === params.row.eventType) || {}).desc }}</span>
          </template>
          <template slot-scope="params" slot="operation">
            <Button @click="showData(params.row)" v-grant="'OSP_MARKETING_LOG_VIEW'" v-show="params.row.pointsResult === 0 || params.row.couponResult === 0 || params.row.couponResult === 2">查看</Button>
          </template>
        </sp-table>
      </div>
    </div>

    <Modal title="失败原因" v-model="logModal.display" width="800" footer-hide>
      <Table :columns="logColumns" :data="logModal.list" border></Table>
    </Modal>
  </div>
</template>

<script>
  import { EVENT_EXECUTE_RESULT, EVENT_TRIGGER_MODE } from '@/util/enum';

  export default {
    data() {
      return {
        formData: {
          eventId: '',  // 事件ID
          eventName: '',  // 事件名称
          eventType: null,  // 事件类型，0:会员注册;1:实名认证;2:自定义事件
          executeResult: '',
          memberId: ''
        },
        tableColumns: [
          {
            title: '触达方式',
            render: (h, params) => {
              return h('span', EVENT_TRIGGER_MODE[params.row.triggerMode]);
            }
          },
          {
            title: '事件ID',
            key: 'eventId'
          },
          {
            title: '事件类型',
            slot: 'eventType'
          },
          {
            title: '事件名称',
            key: 'eventName'
          },
          {
            title: '会员编码',
            key: 'memberId'
          },
          {
            title: '执行结果',
            render: (h, params) => {
              return h('span', EVENT_EXECUTE_RESULT[params.row.executeResult]);
            }
          },
          {
            title: '执行时间',
            key: 'createdTime'
          },
          {
            title: '积分发放结果',
            render: (h, params) => {
              return h('span', EVENT_EXECUTE_RESULT[params.row.pointsResult]);
            }
          },
          {
            title: '积分发放数',
            key: 'points'
          },
          {
            title: '优惠券发放结果',
            render: (h, params) => {
              return h('div', EVENT_EXECUTE_RESULT[params.row.couponResult]);
            }
          },
          {
            title: '失败原因',
            slot: 'operation',
            align: 'center'
          }
        ],
        tableData: [],
        tableLoading: false, // 表格查询状态
        pager: {
          total: 0,     // 总条数
          pageNum: 1,   // 当前页码
          pageSize: 10  // 每页条数
        },
        eventTypeList: [],
        executeResultList: EVENT_EXECUTE_RESULT,
        logModal: {
          display: false,
          list: []
        },
        logColumns: [
          {
            title: '业务类型',
            key: 'type'
          },
          {
            title: '失败原因',
            key: 'desc'
          }
        ]
      };
    },
    computed: {
      filterColumns() {
        let val = [];
        let grant = [this.$grant.OSP_MARKETING_LOG_VIEW];
        if (!this.checkGrant(grant)) {
          val = this.tableColumns.filter(item => item.slot !== 'operation');
        } else {
          val = this.tableColumns;
        }
        return val;
      }
    },
    created() {
      this.getData();
      this.getTypeList();
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        let data = Object.assign({}, this.formData, {
          pageNum: this.pager.pageNum,
          pageSize: this.pager.pageSize
        });
        this.$axios({
          url: this.$api.marketing.queryLogList,
          data,
          beforeSend: () => {
            this.tableLoading = true;
          },
          complete: () => {
            this.tableLoading = false;
          }
        }).then(data => {
          this.tableData = data.list || [];
          this.pager.total = data.total || 0;
        });
      },
      /**
       * 获取事件类型列表
       */
      getTypeList() {
        this.$axios({
          url: this.$api.marketing.queryEventType,
          data: {}
        }).then(data => {
          this.eventTypeList = data || [];
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 查看
       */
      showData(row) {
        let list = [];
        this.logModal.display = true;
        if (row.pointsResult === 0) {
          list.push({
            type: '积分',
            desc: row.pointsResultDesc
          });
        }
        if (row.couponResult === 0 || row.couponResult === 2) {
          list.push({
            type: '券',
            desc: row.couponResultDesc
          });
        }
        this.logModal.list = list;
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      }
    }
  };
</script>